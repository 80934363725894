import "./Signup.css";

import { API, Auth } from "aws-amplify";
import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  Row,
} from "react-bootstrap";
import React, { useState } from "react";

import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";

export default function Signup(props) {
  const [fields, handleFieldChange] = useFormFields({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const [questionName, setQuestionName] = useState("accountForm");
  const [hoursDaily, setHoursDaily] = useState("0");
  const [inboundRequests, setInboundRequests] = useState("0");
  const [outboundRequests, setOutboundRequests] = useState("0");
  const [conversationsCount, setConversationsCount] = useState("0");
  const [hasList, setHasList] = useState("no");
  const [emailSender, setEmailSender] = useState("none");
  const [crm, setCrm] = useState("none");
  const [howComfortable, setHowComfortable] = useState("no");
  const [mostImportant, setMostImportant] = useState("network");
  const [prospectSize, setProspectSize] = useState("1-10");
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [linkedinUrl, setLinkedinUrl] = useState("");

  function renderForm() {
    switch (questionName) {
      case "hoursDaily":
        return hoursDailyQuestion();
      case "inboundRequests":
        return inboundRequestsQuestion();
      case "outboundRequests":
        return outboundRequestsQuestion();
      case "conversationsCount":
        return conversationsCountQuestion();
      case "hasList":
        return hasListQuestion();
      case "emailSender":
        return emailSenderQuestion();
      case "crm":
        return crmQuestion();
      case "howComfortable":
        return howComfortableQuestion();
      case "mostImportant":
        return mostImportantQuestion();
      case "linkedinUrl":
        return linkedinUrlQuestion();
      case "prospectSize":
        return prospectSizeQuestion();
      case "accountForm":
        return accountForm();
      default:
        return null;
    }
  }

  function handleLinkedinUrlValidation() {
    return linkedinUrl.length >= 3;
  }

  function handleLinkedinUrlChange(evt) {
    setLinkedinUrl(evt.target.value);
  }

  function linkedinUrlQuestion() {
    return (
      <div>
        <h5>What is your LinkedIn profile url?</h5>
        <FormControl
          type="text"
          value={linkedinUrl}
          onChange={handleLinkedinUrlChange}
          placeholder="https://www.linkedin.com/in/davidhurley1/"
        />
        <button
          disabled={!handleLinkedinUrlValidation()}
          onClick={() => setQuestionName("prospectSize")}
          className="btn-primary"
        >
          Next
        </button>
      </div>
    );
  }

  function mostImportantQuestion() {
    return (
      <div>
        <h5>How can we help you the most?</h5>
        <p onClick={() => setMostImportant("network")}>
          <input
            type="radio"
            checked={mostImportant === "network" ? "checked" : ""}
          />{" "}
          I want to grow my network on LinkedIn
        </p>
        <p onClick={() => setMostImportant("sales")}>
          <input
            type="radio"
            checked={mostImportant === "sales" ? "checked" : ""}
          />{" "}
          I'd like to book more sales meetings
        </p>
        <p onClick={() => setMostImportant("advice")}>
          <input
            type="radio"
            checked={mostImportant === "advice" ? "checked" : ""}
          />{" "}
          I'd like connect with other people in my industry
        </p>
        <p onClick={() => setMostImportant("other")}>
          <input
            type="radio"
            checked={mostImportant === "other" ? "checked" : ""}
          />{" "}
          other
        </p>
        <button
          onClick={() => setQuestionName("prospectSize")}
          className="btn-primary"
        >
          Next
        </button>
      </div>
    );
  }

  function howComfortableQuestion() {
    return (
      <div>
        <h5>Should conversations be automated on LinkedIn?</h5>
        <p onClick={() => setHowComfortable("no")}>
          <input
            type="radio"
            checked={howComfortable === "no" ? "checked" : ""}
          />{" "}
          no
        </p>
        <p onClick={() => setHowComfortable("yes")}>
          <input
            type="radio"
            checked={howComfortable === "yes" ? "checked" : ""}
          />{" "}
          yes{" "}
        </p>
        <p onClick={() => setHowComfortable("some")}>
          <input
            type="radio"
            checked={howComfortable === "some" ? "checked" : ""}
          />{" "}
          only some conversations
        </p>
        <p onClick={() => setHowComfortable("most")}>
          <input
            type="radio"
            checked={howComfortable === "most" ? "checked" : ""}
          />{" "}
          most conversations
        </p>
        <button
          onClick={() => setQuestionName("mostImportant")}
          className="btn-primary"
        >
          Next
        </button>
      </div>
    );
  }

  function prospectSizeQuestion() {
    return (
      <div>
        <h5>What size companies do you sell into?</h5>
        <p onClick={() => setProspectSize("1-10")}>
          <input
            type="radio"
            checked={prospectSize === "1-10" ? "checked" : ""}
          />{" "}
          1-10 Employees
        </p>
        <p onClick={() => setProspectSize("11-50")}>
          <input
            type="radio"
            checked={prospectSize === "11-50" ? "checked" : ""}
          />{" "}
          11-50 Employees{" "}
        </p>
        <p onClick={() => setProspectSize("51-200")}>
          <input
            type="radio"
            checked={prospectSize === "51-200" ? "checked" : ""}
          />{" "}
          51-200 Employees
        </p>
        <p onClick={() => setProspectSize("200+")}>
          <input
            type="radio"
            checked={prospectSize === "200+" ? "checked" : ""}
          />{" "}
          200+ Employees
        </p>
        <button
          onClick={() => setQuestionName("accountForm")}
          className="btn-primary"
        >
          Next
        </button>
      </div>
    );
  }

  function emailSenderQuestion() {
    return (
      <div>
        <h5>Which tool do you use to send cold emails?</h5>
        <p onClick={() => setEmailSender("none")}>
          <input
            type="radio"
            checked={emailSender === "none" ? "checked" : ""}
          />{" "}
          I send cold emails manually
        </p>
        <p onClick={() => setEmailSender("Outreach")}>
          <input
            type="radio"
            checked={emailSender === "Outreach" ? "checked" : ""}
          />{" "}
          Outreach{" "}
        </p>
        <p onClick={() => setEmailSender("SalesLoft")}>
          <input
            type="radio"
            checked={emailSender === "SalesLoft" ? "checked" : ""}
          />{" "}
          SalesLoft
        </p>
        <p onClick={() => setEmailSender("other")}>
          <input
            type="radio"
            checked={emailSender === "other" ? "checked" : ""}
          />{" "}
          other
        </p>
        <button onClick={() => setQuestionName("crm")} className="btn-primary">
          Next
        </button>
      </div>
    );
  }

  function hasListQuestion() {
    return (
      <div>
        <h5>Do you use the custom lists feature in Sales Navigator?</h5>
        <p onClick={() => setHasList("no")}>
          <input type="radio" checked={hasList === "no" ? "checked" : ""} /> no
        </p>
        <p onClick={() => setHasList("yes")}>
          <input type="radio" checked={hasList === "yes" ? "checked" : ""} />{" "}
          yes
        </p>
        <p onClick={() => setHasList("many")}>
          <input type="radio" checked={hasList === "many" ? "checked" : ""} /> I
          have many lists
        </p>
        <p onClick={() => setHasList("not sure")}>
          <input
            type="radio"
            checked={hasList === "not sure" ? "checked" : ""}
          />{" "}
          I'm not sure what this question means
        </p>
        <button
          onClick={() => setQuestionName("emailSender")}
          className="btn-primary"
        >
          Next
        </button>
      </div>
    );
  }

  function conversationsCountQuestion() {
    return (
      <div>
        <h5>How many conversations do have on LinkedIn currently?</h5>
        <p onClick={() => setConversationsCount("0")}>
          <input
            type="radio"
            checked={conversationsCount === "0" ? "checked" : ""}
          />{" "}
          0
        </p>
        <p onClick={() => setConversationsCount("1 - 2")}>
          <input
            type="radio"
            checked={conversationsCount === "1 - 2" ? "checked" : ""}
          />{" "}
          1 - 2
        </p>
        <p onClick={() => setConversationsCount("2 - 5")}>
          <input
            type="radio"
            checked={conversationsCount === "2 - 5" ? "checked" : ""}
          />{" "}
          2 - 5
        </p>
        <p onClick={() => setConversationsCount("5+")}>
          <input
            type="radio"
            checked={conversationsCount === "5+" ? "checked" : ""}
          />{" "}
          5+
        </p>
        <button
          onClick={() => setQuestionName("hasList")}
          className="btn-primary"
        >
          Next
        </button>
      </div>
    );
  }

  function hoursDailyQuestion() {
    return (
      <div>
        <h5>How many hours a day do you use LinkedIn?</h5>
        <p onClick={() => setHoursDaily("0")}>
          <input type="radio" checked={hoursDaily === "0" ? "checked" : ""} /> 0
        </p>
        <p onClick={() => setHoursDaily("1")}>
          <input type="radio" checked={hoursDaily === "1" ? "checked" : ""} /> 1
          - 2
        </p>
        <p onClick={() => setHoursDaily("2")}>
          <input type="radio" checked={hoursDaily === "2" ? "checked" : ""} /> 2
          - 5
        </p>
        <p onClick={() => setHoursDaily("3")}>
          <input type="radio" checked={hoursDaily === "3" ? "checked" : ""} />{" "}
          5+
        </p>
        <button
          onClick={() => setQuestionName("inboundRequests")}
          className="btn-primary"
        >
          Next
        </button>
      </div>
    );
  }

  function inboundRequestsQuestion() {
    return (
      <div>
        <h5>How many connection requests do you recieve daily?</h5>
        <p onClick={() => setInboundRequests("0")}>
          <input
            type="radio"
            checked={inboundRequests === "0" ? "checked" : ""}
          />{" "}
          0
        </p>
        <p onClick={() => setInboundRequests("1")}>
          <input
            type="radio"
            checked={inboundRequests === "1" ? "checked" : ""}
          />{" "}
          1
        </p>
        <p onClick={() => setInboundRequests("2")}>
          <input
            type="radio"
            checked={inboundRequests === "2" ? "checked" : ""}
          />{" "}
          2
        </p>
        <p onClick={() => setInboundRequests("3")}>
          <input
            type="radio"
            checked={inboundRequests === "3" ? "checked" : ""}
          />{" "}
          3+
        </p>
        <button
          onClick={() => setQuestionName("outboundRequests")}
          className="btn-primary"
        >
          Next
        </button>
      </div>
    );
  }

  function outboundRequestsQuestion() {
    return (
      <div>
        <h5>How many connection requests do you send daily?</h5>
        <p onClick={() => setOutboundRequests("0")}>
          <input
            type="radio"
            checked={outboundRequests === "0" ? "checked" : ""}
          />{" "}
          0
        </p>
        <p onClick={() => setOutboundRequests("1-10")}>
          <input
            type="radio"
            checked={outboundRequests === "1-10" ? "checked" : ""}
          />{" "}
          1 - 10
        </p>
        <p onClick={() => setOutboundRequests("10-30")}>
          <input
            type="radio"
            checked={outboundRequests === "10-30" ? "checked" : ""}
          />{" "}
          10 - 30
        </p>
        <p onClick={() => setOutboundRequests("30+")}>
          <input
            type="radio"
            checked={outboundRequests === "30+" ? "checked" : ""}
          />{" "}
          30+
        </p>
        <button
          onClick={() => setQuestionName("conversationsCount")}
          className="btn-primary"
        >
          Next
        </button>
      </div>
    );
  }

  function crmQuestion() {
    return (
      <div>
        <h5>Which CRM do you use?</h5>
        <p onClick={() => setCrm("none")}>
          <input type="radio" checked={crm === "none" ? "checked" : ""} /> I
          don't use a CRM
        </p>
        <p onClick={() => setCrm("Salesforce")}>
          <input type="radio" checked={crm === "Salesforce" ? "checked" : ""} />{" "}
          Salesforce{" "}
        </p>
        <p onClick={() => setCrm("Hubspot")}>
          <input type="radio" checked={crm === "Hubspot" ? "checked" : ""} />{" "}
          Hubspot
        </p>
        <p onClick={() => setCrm("other")}>
          <input type="radio" checked={crm === "other" ? "checked" : ""} />{" "}
          other
        </p>
        <button
          onClick={() => setQuestionName("howComfortable")}
          className="btn-primary"
        >
          Next
        </button>
      </div>
    );
  }

  function accountForm() {
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col xs={6}>
              <FormGroup controlId="firstName" bsSize="large">
                <ControlLabel>First Name</ControlLabel>
                <FormControl
                  autoFocus
                  type="text"
                  value={fields.firstName}
                  onChange={handleFieldChange}
                />
              </FormGroup>
            </Col>
            <Col xs={6}>
              <FormGroup controlId="lastName" bsSize="large">
                <ControlLabel>Last Name</ControlLabel>
                <FormControl
                  type="text"
                  value={fields.lastName}
                  onChange={handleFieldChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              type="password"
              value={fields.password}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <FormGroup
            className="hidden"
            controlId="confirmPassword"
            bsSize="large"
          >
            <ControlLabel>Confirm Password</ControlLabel>
            <FormControl
              type="password"
              onChange={handleFieldChange}
              value={fields.password}
            />
          </FormGroup>
          <LoaderButton
            className="btn-primary"
            type="submit"
            bsSize="large"
            isLoading={isLoading}
            disabled={!validateForm()}
          >
            Sign Up
          </LoaderButton>
        </form>
      </div>
    );
  }

  function validateForm() {
    return (
      fields.email.length > 5 &&
      fields.password.length > 5 &&
      fields.firstName.length > 1 &&
      fields.lastName.length > 1
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const newUser = await Auth.signUp({
        attributes: {
          family_name: fields.lastName,
          given_name: fields.firstName,
        },
        username: fields.email,
        password: fields.password,
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  function createProfile() {
    return API.post("profiles", "/profiles", {
      body: {
        email: fields.email,
        firstName: fields.firstName,
        lastName: fields.lastName,
        hoursDaily,
        inboundRequests,
        outboundRequests,
        conversationsCount,
        hasList,
        emailSender,
        crm,
        paid: false,
        howComfortable,
        mostImportant,
        linkedinUrl: "none",
        city: "none",
        prospectSize,
        idealCustomer: "none",
        connectionRequest: "none",
        firstFollowup: "none",
        refCode: "ga-" + props.match.params.id,
      },
    });
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);
      const thisProfile = await createProfile();

      if (thisProfile) {
        props.userHasAuthenticated(true);
        props.history.push("/dashboard");
      } else {
        await createProfile();
        props.userHasAuthenticated(true);
        props.history.push("/dashboard");
      }
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <div>
        <form onSubmit={handleConfirmationSubmit}>
          <FormGroup controlId="confirmationCode" bsSize="large">
            <ControlLabel>Confirmation Code</ControlLabel>
            <FormControl
              type="tel"
              onChange={handleFieldChange}
              value={fields.confirmationCode}
            />
            <HelpBlock>Please check your email for the code.</HelpBlock>
          </FormGroup>
          <LoaderButton
            block
            type="submit"
            bsSize="large"
            isLoading={isLoading}
            disabled={!validateConfirmationForm()}
          >
            Verify
          </LoaderButton>
        </form>
      </div>
    );
  }

  return (
    <div className="Signup container text-center">
      <Row>
        <Col sm={8} smOffset={2}>
          <div className="form-wrapper">
            {newUser === null ? renderForm() : renderConfirmationForm()}
          </div>
        </Col>
      </Row>
    </div>
  );
}
