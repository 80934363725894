import "./NewTemplate.css";

import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import MdArrowBack from "react-ionicons/lib/MdArrowBack";
import IosInformationCircle from "react-ionicons/lib/IosInformationCircle";
import MdArrowDropleftCircle from "react-ionicons/lib/MdArrowDropleftCircle";
import MdArrowDroprightCircle from "react-ionicons/lib/MdArrowDroprightCircle";
import IosArrowDown from "react-ionicons/lib/IosArrowDown";
import IosArrowUp from "react-ionicons/lib/IosArrowUp";
import MdArrowForward from "react-ionicons/lib/MdArrowForward";
import MdCheckmark from "react-ionicons/lib/MdCheckmark";
import axios from "axios";
import LoaderButton from "../components/LoaderButton";

export default function NewTemplate(props) {
  const [title, setTitle] = useState("");
  const [connectionRequestText, setConnectionRequestText] = useState(
    "Hi {{firstName}}, How much does a polar bear weigh? Enough to break the ice!"
  );
  const [firstFollowUpText, setFirstFollowUpText] = useState(
    "Thanks for connecting, {{firstName}}. Would love to get your advice on something I'm working on."
  );
  const [firstFollowUpDays, setFirstFollowUpDays] = useState(1);
  const [hasFirstFollowup, setHasFirstFollowup] = useState(true);
  const [secondFollowUpText, setSecondFollowUpText] = useState(null);
  const [secondFollowUpDays, setSecondFollowUpDays] = useState(2);
  const [hasSecondFollowup, setHasSecondFollowup] = useState(false);
  const [thirdFollowUpText, setThirdFollowUpText] = useState(null);
  const [thirdFollowUpDays, setThirdFollowUpDays] = useState(3);
  const [hasThirdFollowup, setHasThirdFollowup] = useState(false);
  const [fourthFollowUpText, setFourthFollowUpText] = useState(null);
  const [fourthFollowUpDays, setFourthFollowUpDays] = useState(4);
  const [hasFourthFollowup, setHasFourthFollowup] = useState(false);
  const [fifthFollowUpText, setFifthFollowUpText] = useState(null);
  const [fifthFollowUpDays, setFifthFollowUpDays] = useState(5);
  const [hasFifthFollowup, setHasFifthFollowup] = useState(false);
  const [sixthFollowUpText, setSixthFollowUpText] = useState(null);
  const [sixthFollowUpDays, setSixthFollowUpDays] = useState(6);
  const [hasSixthFollowup, setHasSixthFollowup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState("title");
  const [templateCreated, setTemplateCreated] = useState(false);
  const [crIndex, setCrIndex] = useState(0);
  const [profile, setProfile] = useState(null);
  const [templateVars, setTemplateVars] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const [showConnectionRequest, setShowConnectionRequest] = useState(false);
  const [description, setDescription] = useState("");
  const [crSsuggestions, setCrSuggestions] = useState([
    "Hi {{firstName}}, I’m launching a (free) platform later this month that might interest you. If you’d like to see more, let’s connect and I’ll show you.",
    "Hi {{firstName}}, this may sound random but I'll ask anyway - do you golf?",
    "Hi {{firstName}}, How much does a polar bear weigh? Enough to break the ice!",
    "Hi {{firstName}}, I'm inviting a few {{company}} employees to a webinar we are hosting at the end of the month. Would you like me to add your name to the list?",
    "Hey {{firstName}}, We’ve never met but I am co-founder at one of {{location}}'s top growth agencies. I created a personalized video for you breaking down some ideas for your growth and ad strategy. Cool if I send it over?",
    "Hi {{firstName}}, had some ideas around ambassador marketing. Thought this would be of interest to you, be great to connect.",
    "Hi {{firstName}}, I came across your profile today and would love to connect! I’ve partnered up with quite a few {{company}} employees across the country to help them with their finances.",
    "Hi {{firstName}}, suit guessing on LinkedIn is one of my favourite games. Is that a Harry Rosen suit you're wearing in your profile pic?",
    "Hi {{firstName}}, {{company}} came up in my research when looking for innovative teams that use {{custom1}}. I was wondering if I could get your feedback on a tool we've been building? I'm just trying to understand if there is a market for a what we're building.",
    "Hi {{firstName}}, have you ever wanted to learn how to code? I'm hosting an online workshop next week where I'll show people automate their LinkedIn account.",
  ]);
  const [connectionRequestChanged, setConnectionRequestChanged] = useState(
    false
  );
  const [responseScore, setResponseScore] = useState(null);

  useEffect(() => {
    async function onLoad() {
      try {
        const user = await Auth.currentUserInfo();
        const profile = await loadProfile(user["id"]);
        setProfile(profile);
      } catch (e) {
        
      }
    }

    onLoad();
  }, []);

  function validateForm() {
    return (
      title.length > 1 &&
      Number.isInteger(parseInt(firstFollowUpDays)) &&
      Number.isInteger(parseInt(secondFollowUpDays)) &&
      Number.isInteger(parseInt(thirdFollowUpDays)) &&
      Number.isInteger(parseInt(fourthFollowUpDays)) &&
      Number.isInteger(parseInt(fifthFollowUpDays)) &&
      Number.isInteger(parseInt(sixthFollowUpDays))
    );
  }

  async function suggestCrFoward() {
    const crTemplates = crSsuggestions;
    let newIndex = crIndex + 1;
    if (newIndex === crTemplates.length) newIndex = 0;
    setConnectionRequestText(crTemplates[newIndex]);
    setCrIndex(newIndex);
    setConnectionRequestChanged(false);
    await checkMessage();
  }

  async function suggestCrBack() {
    const crTemplates = crSsuggestions;
    let newIndex = crIndex - 1;
    if (newIndex === -1) newIndex = crTemplates.length - 1;
    setConnectionRequestText(crTemplates[newIndex]);
    setCrIndex(newIndex);
    setConnectionRequestChanged(false);
    await checkMessage();
  }

  const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    const template = {
      userId: "us-east-1:c050308b-124e-4d87-8037-d6dc8afb12a6",
      title,
      description,
      connectionRequestText,
      firstFollowUpText,
      firstFollowUpDays,
      hasFirstFollowup: firstFollowUpText && firstFollowUpText.length > 0,
      secondFollowUpText,
      secondFollowUpDays,
      hasSecondFollowup: secondFollowUpText && secondFollowUpText.length > 0,
      thirdFollowUpText,
      thirdFollowUpDays,
      hasThirdFollowup: thirdFollowUpText && thirdFollowUpText.length > 0,
      fourthFollowUpText,
      fourthFollowUpDays,
      hasFourthFollowup: fourthFollowUpText && fourthFollowUpText.length > 0,
      fifthFollowUpText,
      fifthFollowUpDays,
      hasFifthFollowup: fifthFollowUpText && fifthFollowUpText.length > 0,
      sixthFollowUpText,
      sixthFollowUpDays,
      hasSixthFollowup: sixthFollowUpText && sixthFollowUpText.length > 0,
      suggestion1: crSsuggestions[0],
      suggestion2: crSsuggestions[1],
      suggestion3: crSsuggestions[2],
      suggestion4: crSsuggestions[3],
      accountId: props.accountId
    };

    try {
      await createTemplate(template);

      if (props.onboarding) {
        const campaign_input = {
          title,
          dailyLimit: 40,
          accountId: props.accountId,
          profileId: profile.profileId,
        };
        console.log(campaign_input);

        await pause(10000);
        props.props.history.push(`/accounts/${props.accountId}`);
      } else {
        setTemplateCreated(true);
      }
    } catch (e) {
      alert(e);
      setIsLoading(false);
      
    }
  }

  function loadProfile(userId) {
    return API.get("profiles", `/myprofile/${userId}`);
  }

  function createTemplate(template) {
    return API.post("templates", "/templates", {
      body: template,
    });
  }

  function handleFirstFollowup() {
    setHasFirstFollowup(!hasFirstFollowup);
  }

  function handleSecondFollowup() {
    setHasSecondFollowup(!hasSecondFollowup);
  }

  function handleThirdFollowup() {
    setHasThirdFollowup(!hasThirdFollowup);
  }

  function handleFourthFollowup() {
    setHasFourthFollowup(!hasFourthFollowup);
  }

  function handleFifthFollowup() {
    setHasFifthFollowup(!hasFifthFollowup);
  }

  function handleSixthFollowup() {
    setHasSixthFollowup(!hasSixthFollowup);
  }

  function checkTemplateVariables(text) {
    const start = "{{";
    const finish = "}}";
    let index = -1;
    const starts = [];
    const finishes = [];
    const variables = [
      "firstName",
      "lastName",
      "position",
      "company",
      "location",
      "custom1",
      "custom2",
      "custom3",
      "custom4",
      "custom5",
      "custom6",
      "custom7",
      "custom8",
      "custom9",
      "custom10",
    ];

    let stopThis = true;

    if (text && text.length > 0) {
      while ((index = text.indexOf(start, index + 1)) > -1) {
        starts.push(index);
      }
      index = -1;
      while ((index = text.indexOf(finish, index + 1)) > -1) {
        finishes.push(index);
      }

      for (let i in starts) {
        const thisVariable = text.slice(starts[i] + 2, finishes[i]);
        if (!variables.includes(thisVariable)) {
          stopThis = false;
          break;
        }
      }
    }

    return stopThis;
  }

  function getCompletions(prompt) {
    return API.post("templates", "/openai", {
      body: prompt,
    });
  }

  function removeA(arr) {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L > 1 && arr.length) {
      what = a[--L];
      while ((ax = arr.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  }

  async function getRobotCompletions() {
    setIsLoading(true);
    try {
      const res = await getCompletions({
        offer: description,
        audience: title,
      });
      const templateSuggestions = removeA(res.completions, "");
      if (templateSuggestions && templateSuggestions.length > 0)
        setCrSuggestions(templateSuggestions);
      setConnectionRequestText(templateSuggestions[0]);
      setShowConnectionRequest(true);
      await checkMessage();
      setIsLoading(false);
    } catch (e) {
      alert(e);
      setShowConnectionRequest(true);
      setConnectionRequestText(crSsuggestions[0]);
      setIsLoading(false);
    }
  }

  function handleRequestValidation() {
    console.log(connectionRequestText.length);
    return title.length > 3 && connectionRequestText.length < 296;
  }

  async function checkMessage() {
    setShowNext(true);
    setConnectionRequestChanged(true);
    try {
      const res = await axios.post(
        "https://api-inference.huggingface.co/models/conversify/response-score",
        {
          inputs: connectionRequestText,
          options: {
            wait_for_model: true,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer api_FQmXqFCRtTtWUzulScJAOdFAFzcpEAgHSf",
          },
        }
      );

      const goodChance = res.data[0][0];
      const badChance = res.data[0][1];

      if (goodChance.score > badChance.score) {
        let color = "#2ecc71";
        let label = "looks good";
        if (goodChance.score < 0.8) {
          color = "#f1c40f";
          label = "needs some work";
        }
        setResponseScore({
          label,
          score: goodChance.score.toFixed(2),
          color,
        });
      } else {
        let color = "#f1c40f";
        let label = "needs some work";
        if (badChance.score < 0.9) {
          color = "#f1c40f";
          label = "needs some work";
        }
        setResponseScore({
          label,
          score: (1 - badChance.score).toFixed(2),
          color,
        });
      }
    } catch (e) {
      
    }
  }

  function handleConnectionResponseScoreCheck() {
    return title.length > 1 && !connectionRequestChanged;
  }

  function handleGenerateTemplatesCheck() {
    return title.length > 1 && description.length > 1;
  }

  function changeConnectionRequest(e) {
    setConnectionRequestText(e.target.value);
    setConnectionRequestChanged(false);
  }

  return (
    <div className="NewTemplate">
      {templateCreated ? (
        <div className="text-center">
          <h1>
            <MdCheckmark fontSize="60px" color="#00D3FB" />
          </h1>
          <p style={{ textAlign: "center" }}>Template created!</p>
          <p style={{ textAlign: "center" }}>
            <button
              style={{ margin: "auto", marginTop: "15px" }}
              onClick={() => props.setScreen("campaigns")}
              className="btn-secondary"
            >
              Create campaign
            </button>
          </p>
        </div>
      ) : (
        <Row>
          <Col sm={12}>
            <form onSubmit={handleSubmit}>
              {question === "title" ? (
                <div>
                  <p>
                    Who do you want to connect with and what are you offering?
                  </p>
                  <FormGroup controlId="title">
                    {showConnectionRequest && title.length <= 3 ? (
                      <div className="warning-label">
                        <p className="text-center">
                          this needs to longer than 3 characters
                        </p>
                      </div>
                    ) : null}
                    <ControlLabel>
                      <OverlayTrigger
                        key={"right3"}
                        placement={"right"}
                        overlay={
                          <Tooltip id={`tooltip-${"right3"}`}>
                            <strong>Audience</strong> - Who are you selling to?
                            Include their job title and location. An example
                            audience could be "Marketing leaders in New York".
                          </Tooltip>
                        }
                      >
                        <span variant="secondary">
                          <b>Audience</b>{" "}
                          <IosInformationCircle
                            color="#00D3FB"
                            fontSize="21px"
                          />
                        </span>
                      </OverlayTrigger>
                    </ControlLabel>
                    <FormControl
                      value={title}
                      type="text"
                      placeholder="startup founders in Toronto"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup controlId="title">
                    <ControlLabel>
                      <OverlayTrigger
                        key={"right3"}
                        placement={"right"}
                        overlay={
                          <Tooltip id={`tooltip-${"right3"}`}>
                            <strong>Offer</strong> - what are you promoting? For
                            example, you could offer "free eBook on digital
                            marketing strategies".
                          </Tooltip>
                        }
                      >
                        <span variant="secondary">
                          <b>Offer</b>{" "}
                          <IosInformationCircle
                            color="#00D3FB"
                            fontSize="21px"
                          />
                        </span>
                      </OverlayTrigger>
                    </ControlLabel>
                    <FormControl
                      value={description}
                      type="text"
                      placeholder="webinar on raising capitial while working remote"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup controlId="title">
                    <LoaderButton
                      className="other-btn pull-left"
                      bsSize="large"
                      onClick={() => getRobotCompletions()}
                      disabled={!handleGenerateTemplatesCheck()}
                      isLoading={isLoading}
                    >
                      Generate template suggestions
                    </LoaderButton>
                  </FormGroup>

                  {showConnectionRequest ? (
                    <FormGroup controlId="connectionRequest">
                      <br className="clearfix" />
                      <br className="clearfix" />
                      <hr />
                      {!checkTemplateVariables(connectionRequestText) ? (
                        <div className="warning-label">
                          <p className="text-center">
                            One of your variables isn't right
                          </p>
                        </div>
                      ) : null}
                      {connectionRequestText &&
                      connectionRequestText.length > 296 ? (
                        <div className="warning-label">
                          <p className="text-center">
                            Your connection request is too long
                          </p>
                        </div>
                      ) : null}
                      <ControlLabel>
                        <OverlayTrigger
                          key={"right3"}
                          placement={"right"}
                          overlay={
                            <Tooltip id={`tooltip-${"right3"}`}>
                              <strong>Connection Request Note</strong> -
                              LinkedIn allows you to send a note with your
                              connection requests. This message must be less
                              than 300 characters. (you can leave this empty)
                            </Tooltip>
                          }
                        >
                          <span variant="secondary">
                            <b>Connection Request Note</b>{" "}
                            <IosInformationCircle
                              color="#00D3FB"
                              fontSize="21px"
                            />
                          </span>
                        </OverlayTrigger>{" "}
                        <span
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            bottom: "2px",
                          }}
                          onClick={() => suggestCrFoward()}
                          className="pull-right"
                        >
                          <MdArrowDroprightCircle
                            color="#00D3FB"
                            fontSize="21px"
                          />
                        </span>
                        <span
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            bottom: "2px",
                          }}
                          onClick={() => suggestCrBack()}
                          className="pull-right"
                        >
                          <MdArrowDropleftCircle
                            color="#00D3FB"
                            fontSize="21px"
                          />
                        </span>{" "}
                      </ControlLabel>
                      <FormControl
                        value={connectionRequestText}
                        componentClass="textarea"
                        onChange={(e) => changeConnectionRequest(e)}
                      />
                      <LoaderButton
                        className="other-btn pull-left"
                        disabled={!handleConnectionResponseScoreCheck()}
                        onClick={() => checkMessage()}
                        style={{ marginRight: "5px" }}
                        isLoading={isLoading}
                      >
                        Check
                      </LoaderButton>
                      {responseScore && connectionRequestChanged ? (
                        <div>
                          <p
                            className="responseScore pull-left"
                            style={{
                              backgroundColor: responseScore.color,
                              borderColor: responseScore.color,
                            }}
                          >
                            {responseScore.label}
                          </p>
                        </div>
                      ) : null}

                      {showNext ? (
                        <button
                          className="other-btn pull-right"
                          onClick={() => setQuestion("hasFirstFollowup")}
                          disabled={!handleRequestValidation()}
                        >
                          Add follow up
                          <MdArrowForward fontSize="16px" />
                        </button>
                      ) : null}
                    </FormGroup>
                  ) : null}
                </div>
              ) : null}

              {question === "hasFirstFollowup" ? (
                <div>
                  <FormGroup controlId="hasFirstFollowup">
                    {firstFollowUpText && firstFollowUpText.length > 651 ? (
                      <div className="warning-label">
                        <p className="text-center">Your message is too long</p>
                      </div>
                    ) : null}
                    <ControlLabel>
                      <b>Send a follow-up message?</b>{" "}
                      <input
                        checked={hasFirstFollowup}
                        onChange={handleFirstFollowup}
                        type="checkbox"
                      />
                    </ControlLabel>
                    <br />
                    <br />
                  </FormGroup>
                  {hasFirstFollowup ? (
                    <div>
                      {!Number.isInteger(parseInt(firstFollowUpDays)) ? (
                        <div className="warning-label">
                          <p className="text-center">
                            This needs to be a number
                          </p>
                        </div>
                      ) : null}
                      <FormGroup controlId="firstFollowUpDays">
                        <ControlLabel>
                          How many days after will you send that message?
                        </ControlLabel>
                        <FormControl
                          value={firstFollowUpDays}
                          type="number"
                          onChange={(e) => setFirstFollowUpDays(e.target.value)}
                        />
                      </FormGroup>
                      {!checkTemplateVariables(firstFollowUpText) ? (
                        <div className="warning-label">
                          <p className="text-center">
                            One of your variables isn't right
                          </p>
                        </div>
                      ) : null}
                      <FormGroup controlId="firstFollowUpText">
                        <ControlLabel>
                          What will your followup message be?
                        </ControlLabel>
                        <FormControl
                          value={firstFollowUpText}
                          componentClass="textarea"
                          onChange={(e) => setFirstFollowUpText(e.target.value)}
                        />
                      </FormGroup>
                      <br />
                      <FormGroup controlId="hasSecondFollowup">
                        <ControlLabel>
                          <b>Send another follow up message?</b>{" "}
                          <input
                            checked={hasSecondFollowup}
                            onChange={handleSecondFollowup}
                            type="checkbox"
                          />
                        </ControlLabel>
                        <br />
                        <br />
                      </FormGroup>
                    </div>
                  ) : null}

                  {hasSecondFollowup ? (
                    <div>
                      {!Number.isInteger(parseInt(secondFollowUpDays)) ? (
                        <div className="warning-label">
                          <p className="text-center">
                            This needs to be a number
                          </p>
                        </div>
                      ) : null}
                      <FormGroup controlId="secondFollowUpDays">
                        <ControlLabel>How many days after?</ControlLabel>
                        <FormControl
                          value={secondFollowUpDays}
                          type="number"
                          onChange={(e) =>
                            setSecondFollowUpDays(e.target.value)
                          }
                        />
                      </FormGroup>
                      {!checkTemplateVariables(secondFollowUpText) ? (
                        <div className="warning-label">
                          <p className="text-center">
                            One of your variables isn't right
                          </p>
                        </div>
                      ) : null}
                      <FormGroup controlId="secondFollowUpText">
                        {secondFollowUpText &&
                        secondFollowUpText.length > 651 ? (
                          <div className="warning-label">
                            <p className="text-center">
                              Your message is too long
                            </p>
                          </div>
                        ) : null}
                        <ControlLabel>Second Followup Message</ControlLabel>
                        <FormControl
                          value={secondFollowUpText}
                          componentClass="textarea"
                          onChange={(e) =>
                            setSecondFollowUpText(e.target.value)
                          }
                        />
                      </FormGroup>
                      <FormGroup controlId="hasThirdFollowup">
                        <ControlLabel>
                          <b>Send a third follow up message?</b>{" "}
                          <input
                            checked={hasThirdFollowup}
                            onChange={handleThirdFollowup}
                            type="checkbox"
                          />
                        </ControlLabel>
                        <br />
                        <br />
                      </FormGroup>
                      {hasThirdFollowup ? (
                        <div>
                          {!Number.isInteger(parseInt(thirdFollowUpDays)) ? (
                            <div className="warning-label">
                              <p className="text-center">
                                This needs to be a number
                              </p>
                            </div>
                          ) : null}
                          <FormGroup controlId="thirdFollowUpDays">
                            <ControlLabel>
                              How many days after the second message?
                            </ControlLabel>
                            <FormControl
                              value={thirdFollowUpDays}
                              type="number"
                              onChange={(e) =>
                                setThirdFollowUpDays(e.target.value)
                              }
                            />
                          </FormGroup>
                          {!checkTemplateVariables(thirdFollowUpText) ? (
                            <div className="warning-label">
                              <p className="text-center">
                                One of your variables isn't right
                              </p>
                            </div>
                          ) : null}
                          <FormGroup controlId="thirdFollowUpText">
                            {thirdFollowUpText &&
                            thirdFollowUpText.length > 651 ? (
                              <div className="warning-label">
                                <p className="text-center">
                                  Your message is too long
                                </p>
                              </div>
                            ) : null}
                            <ControlLabel>Third Followup Message</ControlLabel>
                            <FormControl
                              value={thirdFollowUpText}
                              componentClass="textarea"
                              onChange={(e) =>
                                setThirdFollowUpText(e.target.value)
                              }
                            />
                          </FormGroup>
                          <FormGroup controlId="hasFourthFollowup">
                            <ControlLabel>
                              <b>Send a fourth follow up message?</b>{" "}
                              <input
                                checked={hasFourthFollowup}
                                onChange={handleFourthFollowup}
                                type="checkbox"
                              />
                            </ControlLabel>
                            <br />
                            <br />
                          </FormGroup>
                          {hasFourthFollowup ? (
                            <div>
                              {!Number.isInteger(
                                parseInt(fourthFollowUpDays)
                              ) ? (
                                <div className="warning-label">
                                  <p className="text-center">
                                    This needs to be a number
                                  </p>
                                </div>
                              ) : null}
                              <FormGroup controlId="fourthFollowUpDays">
                                <ControlLabel>
                                  How many days after the third message?
                                </ControlLabel>
                                <FormControl
                                  value={fourthFollowUpDays}
                                  type="number"
                                  onChange={(e) =>
                                    setFourthFollowUpDays(e.target.value)
                                  }
                                />
                              </FormGroup>
                              {!checkTemplateVariables(fourthFollowUpText) ? (
                                <div className="warning-label">
                                  <p className="text-center">
                                    One of your variables isn't right
                                  </p>
                                </div>
                              ) : null}
                              <FormGroup controlId="fourthFollowUpText">
                                {fourthFollowUpText &&
                                fourthFollowUpText.length > 651 ? (
                                  <div className="warning-label">
                                    <p className="text-center">
                                      Your message is too long
                                    </p>
                                  </div>
                                ) : null}
                                <ControlLabel>
                                  Fourth Followup Message
                                </ControlLabel>
                                <FormControl
                                  value={fourthFollowUpText}
                                  componentClass="textarea"
                                  onChange={(e) =>
                                    setFourthFollowUpText(e.target.value)
                                  }
                                />
                              </FormGroup>
                              <FormGroup controlId="hasFifthFollowup">
                                <ControlLabel>
                                  <b>Send a fifth follow up message?</b>{" "}
                                  <input
                                    checked={hasFifthFollowup}
                                    onChange={handleFifthFollowup}
                                    type="checkbox"
                                  />
                                </ControlLabel>
                                <br />
                                <br />
                              </FormGroup>
                              {hasFifthFollowup ? (
                                <div>
                                  {!Number.isInteger(
                                    parseInt(fifthFollowUpDays)
                                  ) ? (
                                    <div className="warning-label">
                                      <p className="text-center">
                                        This needs to be a number
                                      </p>
                                    </div>
                                  ) : null}
                                  <FormGroup controlId="fifthFollowUpDays">
                                    <ControlLabel>
                                      How many days after the fourth message?
                                    </ControlLabel>
                                    <FormControl
                                      value={fifthFollowUpDays}
                                      type="number"
                                      onChange={(e) =>
                                        setFifthFollowUpDays(e.target.value)
                                      }
                                    />
                                  </FormGroup>
                                  {!checkTemplateVariables(
                                    fifthFollowUpText
                                  ) ? (
                                    <div className="warning-label">
                                      <p className="text-center">
                                        One of your variables isn't right
                                      </p>
                                    </div>
                                  ) : null}
                                  <FormGroup controlId="fifthFollowUpText">
                                    {fifthFollowUpText &&
                                    fifthFollowUpText.length > 651 ? (
                                      <div className="warning-label">
                                        <p className="text-center">
                                          Your message is too long
                                        </p>
                                      </div>
                                    ) : null}
                                    <ControlLabel>
                                      Fifth Followup Message
                                    </ControlLabel>
                                    <FormControl
                                      value={fifthFollowUpText}
                                      componentClass="textarea"
                                      onChange={(e) =>
                                        setFifthFollowUpText(e.target.value)
                                      }
                                    />
                                  </FormGroup>
                                  <FormGroup controlId="hasSixthFollowup">
                                    <ControlLabel>
                                      <b>Send a sixth follow up message?</b>{" "}
                                      <input
                                        checked={hasSixthFollowup}
                                        onChange={handleSixthFollowup}
                                        type="checkbox"
                                      />
                                    </ControlLabel>
                                    <br />
                                    <br />
                                  </FormGroup>
                                  {hasSixthFollowup ? (
                                    <div>
                                      {!Number.isInteger(
                                        parseInt(sixthFollowUpDays)
                                      ) ? (
                                        <div className="warning-label">
                                          <p className="text-center">
                                            This needs to be a number
                                          </p>
                                        </div>
                                      ) : null}
                                      <FormGroup controlId="sixthFollowUpDays">
                                        <ControlLabel>
                                          How many days after the sixth message?
                                        </ControlLabel>
                                        <FormControl
                                          value={sixthFollowUpDays}
                                          type="number"
                                          onChange={(e) =>
                                            setSixthFollowUpDays(e.target.value)
                                          }
                                        />
                                      </FormGroup>
                                      {!checkTemplateVariables(
                                        sixthFollowUpText
                                      ) ? (
                                        <div className="warning-label">
                                          <p className="text-center">
                                            One of your variables isn't right
                                          </p>
                                        </div>
                                      ) : null}
                                      <FormGroup controlId="sixthFollowUpText">
                                        {sixthFollowUpText &&
                                        sixthFollowUpText.length > 651 ? (
                                          <div className="warning-label">
                                            <p className="text-center">
                                              Your message is too long
                                            </p>
                                          </div>
                                        ) : null}
                                        <ControlLabel>
                                          Sixth Followup Message
                                        </ControlLabel>
                                        <FormControl
                                          value={sixthFollowUpText}
                                          componentClass="textarea"
                                          onChange={(e) =>
                                            setSixthFollowUpText(e.target.value)
                                          }
                                        />
                                      </FormGroup>
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              ) : null}

              {question === "hasFirstFollowup" ? (
                <FormGroup controlId="hasFirstFollowup">
                  <span
                    className="other-btn pull-left"
                    onClick={() => setQuestion("title")}
                  >
                    <MdArrowBack fontSize="16px" />
                    Back
                  </span>
                  <button
                    className="other-btn pull-right"
                    onClick={() => setQuestion("previewTemplate")}
                    disabled={
                      (firstFollowUpText &&
                        (firstFollowUpText.length === 0 ||
                          firstFollowUpText.length > 651)) ||
                      (secondFollowUpText &&
                        (secondFollowUpText.length === 0 ||
                          secondFollowUpText.length > 651)) ||
                      (thirdFollowUpText &&
                        (thirdFollowUpText.length === 0 ||
                          thirdFollowUpText.length > 651)) ||
                      (fourthFollowUpText &&
                        (fourthFollowUpText.length === 0 ||
                          fourthFollowUpText.length > 651)) ||
                      (fifthFollowUpText &&
                        (fifthFollowUpText.length === 0 ||
                          fifthFollowUpText.length > 651)) ||
                      (sixthFollowUpText &&
                        (sixthFollowUpText.length === 0 ||
                          sixthFollowUpText.length > 651))
                    }
                  >
                    Next
                    <MdArrowForward fontSize="16px" />
                  </button>
                </FormGroup>
              ) : null}

              <div className="text-center">
                {question === "previewTemplate" ? (
                  <div>
                    <div>
                      <p>
                        <b>Connection Request Note</b>
                      </p>
                      {connectionRequestText.length === 0 ? (
                        <p>
                          <i>
                            ***Connection Request will be sent without a note.
                          </i>
                        </p>
                      ) : (
                        <p>{connectionRequestText}</p>
                      )}
                    </div>
                    {hasFirstFollowup ? (
                      <p className="text-left">
                        <br />
                        <small className="blue">
                          wait {firstFollowUpDays}{" "}
                          {firstFollowUpDays === 1 ? "day" : "days"} after they
                          connect with you and don't reply
                        </small>
                        <br />
                        <br />
                      </p>
                    ) : null}
                    {hasFirstFollowup ? (
                      <div>
                        <p>
                          <b>Follow-up</b>
                        </p>
                        <p>{firstFollowUpText}</p>
                      </div>
                    ) : null}
                    {hasSecondFollowup ? (
                      <p className="text-left">
                        <br />
                        <small className="blue">
                          wait {secondFollowUpDays}{" "}
                          {secondFollowUpDays === 1 ? "day" : "days"}
                        </small>
                        <br />
                        <br />
                      </p>
                    ) : null}
                    {hasSecondFollowup ? (
                      <div>
                        <p>
                          <b>Second Follow-up</b>
                        </p>
                        <p>{secondFollowUpText}</p>
                      </div>
                    ) : null}
                    {hasThirdFollowup ? (
                      <p className="text-left">
                        <br />
                        <small className="blue">
                          wait {thirdFollowUpDays}{" "}
                          {thirdFollowUpDays === 1 ? "day" : "days"}
                        </small>
                        <br />
                        <br />
                      </p>
                    ) : null}
                    {hasThirdFollowup ? (
                      <div>
                        <p>
                          <b>Third Follow-up</b>
                        </p>
                        <p>{thirdFollowUpText}</p>
                      </div>
                    ) : null}
                    {hasFourthFollowup ? (
                      <p className="text-left">
                        <br />
                        <small className="blue">
                          wait {fourthFollowUpDays}{" "}
                          {fourthFollowUpDays === 1 ? "day" : "days"}
                        </small>
                        <br />
                        <br />
                      </p>
                    ) : null}
                    {hasFourthFollowup ? (
                      <div>
                        <p>
                          <b>Fourth Follow-up</b>
                        </p>
                        <p>{fourthFollowUpText}</p>
                      </div>
                    ) : null}
                    {hasFifthFollowup ? (
                      <p className="text-left">
                        <br />
                        <small className="blue">
                          wait {fifthFollowUpDays}{" "}
                          {fifthFollowUpDays === 1 ? "day" : "days"}
                        </small>
                        <br />
                        <br />
                      </p>
                    ) : null}
                    {hasFifthFollowup ? (
                      <div>
                        <p>
                          <b>Fifth Follow-up</b>
                        </p>
                        <p>{fifthFollowUpText}</p>
                      </div>
                    ) : null}
                    {hasSixthFollowup ? (
                      <p className="text-left">
                        <br />
                        <small className="blue">
                          wait {sixthFollowUpDays}{" "}
                          {sixthFollowUpDays === 1 ? "day" : "days"}
                        </small>
                        <br />
                        <br />
                      </p>
                    ) : null}
                    {hasSixthFollowup ? (
                      <div>
                        <p>
                          <b>Sixth Follow-up</b>
                        </p>
                        <p>{sixthFollowUpText}</p>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>

              {question === "previewTemplate" ? (
                <div>
                  <span
                    className="other-btn pull-left"
                    onClick={() => setQuestion("hasFirstFollowup")}
                  >
                    <MdArrowBack fontSize="16px" />
                    Back
                  </span>
                  <LoaderButton
                    className="other-btn pull-right"
                    type="submit"
                    disabled={!validateForm() || isLoading}
                    isLoading={isLoading}
                  >
                    Create Template
                    <MdArrowForward fontSize="16px" />
                  </LoaderButton>
                </div>
              ) : null}
            </form>
            <br />
            {question === "title" ||
            (hasFirstFollowup && question === "hasFirstFollowup") ? (
              <div className="text-left">
                <br className="clearfix" />
                <br />
                <p
                  className="blue"
                  style={{ cursor: "pointer" }}
                  onClick={() => setTemplateVars(!templateVars)}
                >
                  Personsalize your messages{" "}
                  {templateVars ? (
                    <IosArrowUp color="#00D3FB" fontSize="16px" />
                  ) : (
                    <IosArrowDown color="#00D3FB" fontSize="16px" />
                  )}
                </p>
                {templateVars ? (
                  <div>
                    <p>
                      Use the variables below to personlize the messaging in
                      your template.
                    </p>
                    <Row>
                      {[
                        "firstName",
                        "lastName",
                        "position",
                        "company",
                        "location",
                        "custom1",
                        "custom2",
                        "custom3",
                        "custom4",
                        "custom5",
                        "custom6",
                        "custom7",
                        "custom8",
                        "custom9",
                        "custom10",
                      ].map((item) => (
                        <Col key={item} xs={3}>
                          {`{{${item}}}`} <br />
                          <br />
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : null}
              </div>
            ) : null}
          </Col>
        </Row>
      )}
    </div>
  );
}
