import "./ViewAccount.css";

import {
  Col,
  Row,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import Accounts from "./Accounts";
import LoaderButton from "../components/LoaderButton";
import axios from "axios";

export default function ViewAccount(props) {
  const [emailPin, setEmailPin] = useState("");
  const [account, setAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loginError, setLoginError] = useState(false);

  useEffect(() => {
    async function onLoad() {
      try {
        const accounts = await loadAccounts()
        const account = accounts.filter(
          (account) => account.accountId === props.match.params.id
        )[0];
        setAccount(account);
        if (account) {
          setAccount(account);
          console.log(account)
          if (
            "loginError" in account &&
            (account.loginError === "pin.required" ||
              account.loginError === "2fa.required")
          ) {
            // show UI to enter the pin, update account.pin to the pin *as a string*
            setLoginError("pin");
          }

          if (
            "loginError" in account &&
            account.loginError === "credentials.invalid"
          ) {
            setLoginError("creds");
          }

          console.log(account)

          setIsLoading(true);
        } else {
          setIsLoading(true);
          setError(true);
        }
      } catch (e) {
        
        setError(true);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  function deleteAccount() {
    return API.del("accounts", `/accounts/${account.accountId}`);
  }

  function validateForm() {
    return emailPin.trim().length === 6;
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this account?"
    );

    if (!confirmed) {
      return;
    }

    setIsLoading(true);

    try {
      await deleteAccount();
      props.history.push(`/accounts/new`);
    } catch (e) {
      alert(e);
      
      setIsLoading(false);
    }
  }

  function saveAccount(account) {
    return API.put("accounts", `/updatepin/${props.match.params.id}`, {
      body: account,
    });
  }

  const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(false);

    try {
      await saveAccount({
        userId: "us-east-1:c050308b-124e-4d87-8037-d6dc8afb12a6",
        pin: emailPin.trim(),
        accountId: account.accountId,
      });
      await pause(30000);
      setLoginError(false);
      setError(false);
      const newAccounts = await loadAccounts();
      const newAccount = newAccounts.filter(account => account.accountId === props.match.params.id)[0]

      console.log(newAccount)
      setAccount(newAccount)
      setIsLoading(true);
    } catch (e) {
      alert(e);
      
      setIsLoading(false);
    }
  }


  function loadAccounts() {
    return API.post("accounts", "/adminaccounts", {
      body: {
        userId: "us-east-1:c050308b-124e-4d87-8037-d6dc8afb12a6",
      },
    });
  }


  async function refreshAccount() {
    setIsLoading(false);
    verifyAccount(account)
    await pause(30000);
    const newAccounts = await loadAccounts();
    const newAccount = newAccounts.filter(account => account.accountId === props.match.params.id)[0]

    console.log(newAccount)
    console.log(newAccount)
    setLoginError(false)
    if (
      "loginError" in newAccount &&
      newAccount.loginError === "credentials.invalid"
    ) {
      setLoginError("creds");
    }
    if (
      "loginError" in account &&
      (account.loginError === "pin.required" ||
        account.loginError === "2fa.required")
    ) {
      setLoginError("pin");
    }
    setIsLoading(true);
  }

 async function verifyAccount(account) {
    return axios.post(
      "https://legacy.api.conversify.ai/connectAccount",
      null,
      {
        params: { userId: account.userId, accountId: account.accountId }
      }
    ).catch(error => {
      console.log(error)
      props.history.push(`/accounts/${account.accountId}`);
    })
  }

  return (
    <div className="ViewAccount">
      {isLoading ? (
        !account && error ? (
          <div className="NotFound">
            <div className="form-wrapper">
              <h5 className="text-center">This isn't your account.</h5>
              <p className="text-center">
                Either continue or chat with us and we'll help you connect your
                LinkedIn account.
              </p>
              <br />
              <p className="text-center">
                <a
                  href="/dashboard"
                  className="btn-secondary"
                  style={{ margin: "auto" }}
                >
                  Continue
                </a>
              </p>
            </div>
          </div>
        ) : account &&
        account.firstName === "none" &&
        !loginError ? (
        <div className="NotFound">
          <div className="form-wrapper">
            <h5 className="text-center">
              Your account isn't connecting properly.
            </h5>
            <p className="text-center">
              Either continue or chat with us and we'll help you connect your
              LinkedIn account.
            </p>
            <br />
            <p className="text-center">
              <button
                onClick={() => refreshAccount()}
                className="btn-secondary"
                style={{ margin: "auto" }}
              >
                Continue
              </button>
            </p>
          </div>
        </div>
      ) : loginError === "pin" ? (
          <div>
            <br />
            <br />
            <div className="form-wrapper">
              <form onSubmit={handleSubmit}>
                <FormGroup controlId="lastName">
                  <p><b>Check {account.email} for a verification code.</b></p>
                  <ControlLabel>Verification Code</ControlLabel>
                  <FormControl
                    value={emailPin}
                    placeholder="560932"
                    type="text"
                    onChange={(e) => setEmailPin(e.target.value)}
                  />
                </FormGroup>
                <LoaderButton
                  type="submit"
                  className="other-btn"
                  disabled={!validateForm()}
                  isLoading={!isLoading}
                >
                  Save code
                </LoaderButton>
              </form>
            </div>
          </div>
        ) : loginError === "creds" ? (
          <div>
            <br />
            <br />
            <div className="form-wrapper">
              <Row>
                <Col sm={12} className="text-center">
                  <p
                    style={{ opacity: 1, textAlign: "center", margin: "auto" }}
                  >
                    <b>
                      {" "}
                      <span role="img" aria-label="sad">
                        😞
                      </span>{" "}
                      We aren't able to login to your account with the email and
                      password you have saved.
                    </b>
                  </p>

                  <br />
                  <p>
                    You most likely gave us the wrong password. Update your
                    account with the right password to continue.
                  </p>
                  <a
                    className="btn-secondary"
                    style={{ marginTop: "10px" }}
                    href={`/accounts/${account.accountId}/edit`}
                  >
                    Update password
                  </a>
                  <br />
                  <hr />
                  <br />
                  <p>Your LinkedIn email is {account.email}. Is this right?</p>
                  <button
                    style={{ marginTop: "10px" }}
                    className="other-btn"
                    onClick={(e) => handleDelete(e)}
                  >
                    No, delete this account
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div>
            <Row>
              <Col sm={12} xs={12}>
                <Accounts accountId={props.match.params.id} props={props} />
              </Col>
            </Row>
          </div>
        )
      ) : (
        <div>
          <br />
          <br />
          <div className="form-wrapper">
          <div className="text-center">
            <Row>
              <Col sm={12}>
                <p style={{ opacity: 1, textAlign: "center" }}>
                  <b>Loading your account ...</b>
                </p>
                <p style={{ opacity: 1, textAlign: "center" }}>
                  Don't touch anything - this won't take too long
                </p>
                <br />
                <img
                  style={{ width: "100%" }}
                  alt="loading-gif"
                  src="https://conversify-marketing.s3.amazonaws.com/Ryse_GIF.gif"
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      )}
    </div>
  );
}
