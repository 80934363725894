import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.min.css";
import "./Campaigns.css";

import { Col, Modal, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import EditCampaign from "./EditCampaign";
import IosFunnel from "react-ionicons/lib/IosFunnel";
import Loading from "./Loading";
import MdAdd from "react-ionicons/lib/MdAdd";
import MdCreate from "react-ionicons/lib/MdCreate";
import NewCampaign from "./NewCampaign";
import Switch from "react-switch";
import ViewCampaign from "./ViewCampaign";

export default function Campaigns(props) {
  const [campaigns, setCampaigns] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewNewCampaignModel, setViewNewCampaignModel] = useState(false);
  const [viewEditModal, setViewEditModal] = useState(false);
  const [viewCampaignModal, setViewCampaignModal] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [outreachTotal, setOutreachTotal] = useState(0);

  useEffect(() => {
    async function onLoad() {
      try {
        const campaigns = await loadCampaigns();
        const accounts = await loadAccounts();
        const templates = await loadTemplates();

        setTemplates(templates);
        setCampaigns(
          campaigns.filter((campaign) => campaign.accountId === props.accountId)
        );
        setOutreachTotal(
          campaigns
            .filter(
              (campaign) =>
                campaign.accountId === props.accountId &&
                campaign.paused === false
            )
            .reduce((acc, obj) => {
              return acc + obj.dailyLimit;
            }, 0)
        );
        setAccounts(accounts);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated, props.history, props.thisUser, props.accountId]);

  function loadTemplates() {
    return API.post("campaigns", "/admintemplates", {
      body: {
        userId: "us-east-1:c050308b-124e-4d87-8037-d6dc8afb12a6",
      },
    });
  }

  function loadCampaigns() {
    return API.post("campaigns", "/admincampaigns", {
      body: {
        userId: "us-east-1:c050308b-124e-4d87-8037-d6dc8afb12a6",
      },
    });
  }

  function loadAccounts() {
    return API.post("accounts", "/adminaccounts", {
      body: {
        userId: "us-east-1:c050308b-124e-4d87-8037-d6dc8afb12a6",
      },
    });
  }

  function saveCampaign(campaign) {
    return API.put("campaigns", `/campaigns/${campaign.campaignId}`, {
      body: campaign,
    });
  }

  async function handleActivateCampaign(campaign) {
    const updatedCampaign = {
      userId: "us-east-1:c050308b-124e-4d87-8037-d6dc8afb12a6",
      title: campaign.title,
      dailyLimit: campaign.dailyLimit,
      paused: !campaign.paused,
      slug: campaign.slug,
      accountId: campaign.accountId,
      campaignId: campaign.campaignId,
      username: campaign.username,
      contacts: campaign.contacts,
    };

    try {
      await saveCampaign(updatedCampaign);
    } catch (e) {
      alert(e);
    }
  }

  function showEditModal(campaignId) {
    setCampaignId(campaignId);
    setViewEditModal(true);
  }

  function showViewCampaignModal(campaignId) {
    setCampaignId(campaignId);
    setViewCampaignModal(true);
  }

  async function closeModal() {
    const campaigns = await loadCampaigns();
    setCampaigns(
      campaigns.filter((campaign) => campaign.accountId === props.accountId)
    );
    setViewNewCampaignModel(false);
    setViewCampaignModal(false);
    setViewEditModal(false);
  }

  function renderCampaigns() {
    return (
      <div className="Campaigns">
        {accounts.length > 0 ? (
          <Row>
            <Col sm={12}>
              {campaigns.length > 0 ? (
                <div>
                  <Row>
                  <Col xs={4}>
                      <div className="titlePanel pull-left">
                        <span
                          className="other-btn"
                          onClick={() => setViewNewCampaignModel(true)}
                        >
                          <MdAdd fontSize="16px" />
                          New Campaign
                        </span>
                      </div>
                    </Col>
                  </Row>

                  {outreachTotal >= 100 ? (
                    <div className="warning-label">
                      <p>
                        We recommend you send out less than 100 connection
                        requests a day
                      </p>
                    </div>
                  ) : null}

                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Sending Limit</th>
                        <th>Contacts</th>
                        <th>Requests</th>
                        <th>Connections</th>
                        <th>Replies</th>
                        <th>Positive Replies</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {campaigns.map((campaign, i) => (
                        <tr key={campaign.campaignId}>
                          <td
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                showViewCampaignModal(campaign.campaignId)
                              }
                            >
                              {campaign.title}
                            </td>
                          <td>
                            {campaign.dailyLimit}{" "}
                            <span
                              onClick={() =>
                                showEditModal(campaign.campaignId)
                              }
                              className="other-btn"
                            >
                              <MdCreate fontSize="14px" />
                              Update
                            </span>
                          </td>
                          <td>{campaign.contacts}</td>
                          <td>
                            {campaign.requests}{" "}
                            {campaign.contacts > 0 ? (
                              <small className="blue">
                                {parseInt(
                                  (campaign.requests / campaign.contacts) * 100
                                )}
                                %
                              </small>
                            ) : null}
                          </td>
                          <td>
                            {campaign.connections}{" "}
                            {campaign.contacts > 0 ? (
                              <small className="blue">
                                {parseInt(
                                  (campaign.connections / campaign.contacts) *
                                    100
                                )}
                                %
                              </small>
                            ) : null}
                          </td>
                          <td>
                            {campaign.conversations}{" "}
                            {campaign.contacts > 0 ? (
                              <small className="blue">
                                {parseInt(
                                  (campaign.conversations / campaign.contacts) *
                                    100
                                )}
                                %
                              </small>
                            ) : null}
                          </td>
                          <td>
                            {campaign.positives}{" "}
                            {campaign.contacts > 0 ? (
                              <small className="blue">
                                {parseInt(
                                  (campaign.positives / campaign.contacts) * 100
                                )}
                                %
                              </small>
                            ) : null}
                          </td>
                          <td>
                            {campaign.requests > 0 ? (
                              <Switch
                                height={20}
                                width={35}
                                onChange={() =>
                                  handleActivateCampaign(campaign)
                                }
                                onColor={"#00D3FB"}
                                checked={!campaign.paused}
                              />
                            ) : (
                              <span className="blue">
                                {campaign.paused === true ? "Paused" : "Active"}
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : templates.length > 0 ? (
                <div>
                     <div className="text-center">
                      <br />
                      <br />
                      <br />
                      <br />
                      <p className="text-center">
                        <IosFunnel fontSize="64px" color="#00D3FB" />
                      </p>
                      <p>
                        Create your first LinkedIn outreach campaign
                      </p>
                      <p className="text-center">
                        <button
                          onClick={() => setViewNewCampaignModel(true)}
                          className="btn-secondary"
                        >
                          New Campaign
                        </button>
                      </p>
                    </div>
                </div>
              ) : (
                <div className="text-center">
                  <br />
                  <br />
                  <br />
                  <br />
                  <p className="text-center">
                    <IosFunnel fontSize="64px" color="#00D3FB" />
                  </p>
                  <p>You'll need to create a template first</p>
                  <p className="text-center">
                    <button
                      onClick={() => props.changeScreen("templates")}
                      className="btn-secondary"
                    >
                      Create Template
                    </button>
                  </p>
                </div>
              )}
            </Col>
          </Row>
        ) : (
          props.history.push("/accounts/new")
        )}

        <Modal
          show={viewEditModal}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center">
            <button
                onClick={() => closeModal()}
                className="other-btn"
              >
                close
              </button>
              <EditCampaign campaignId={campaignId} props={props} />
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={viewNewCampaignModel} dialogClassName="modal-90w">
          <Modal.Body>
            <div className="modal-card text-center">
              <button
                onClick={() => closeModal()}
                className="other-btn"
              >
                close
              </button>
              <NewCampaign
                setScreen={props.setScreen}
                props={props}
                accountId={props.accountId}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={viewCampaignModal} dialogClassName="modal-90w">
          <Modal.Body>
            <div className="modal-card text-center">
              <button
                onClick={() => closeModal()}
                className="other-btn"
              >
                close
              </button>
              <br />
              <br />
              <ViewCampaign
                changeScreen={props.changeScreen}
                props={props}
                campaignId={campaignId}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  return <div className="">{isLoading ? <Loading /> : renderCampaigns()}</div>;
}
